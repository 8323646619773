import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { navigate } from "gatsby-link";
import api from "../../api";
import { AuthContext } from "../../context/GlobalContextProvider";
import { Button } from "antd";
import PredioComponent from "../generics/predios";

export default function EmAndamento() {
  const [loading, setLoading] = useState(true);
  const [lancamentos, setLancamentos] = useState([]);
  const [emConstrucao, setEmConstrucao] = useState([]);
  const [preLancamento, setPreLancamento] = useState([]);
  const [entregue, setEntregue] = useState([]);

  const context = useContext(AuthContext);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    var response = await api.get("/predios?_sort=created_at:DESC");

    setPreLancamento(response.data.filter((d) => d.estado === "PreLancamento"));
    setLancamentos(response.data.filter((d) => d.estado === "Lancamento"));
    setEmConstrucao(response.data.filter((d) => d.estado === "EmConstrucao"));
    setEntregue(
      response.data.filter(
        (d) => d.estado === "Entregue" || d.estado === "Vendido"
      )
    );

    setLoading(false);
  };

  return (
    <Layout
      aboveFooter
      pageDesctiption="EMPREENDIMENTOS"
      titlePage="Conheça nossos empreendimentos"
      loading={loading}
    >
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Em Andamento"
      />
      {context?.user && (
        <Button
          className="mb-10"
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/emAndamento`)}
        >
          Editar
        </Button>
      )}
      {preLancamento.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "32px",
          }}
        >
          <h2
            style={{
              width: "35%",
              textAlign: "center",
              paddingBottom: "12px",
              borderBottomWidth: "1.5px",
              borderBottomColor: "black",
            }}
          >
            Pré-lançamento
          </h2>
        </div>
      )}
      <PredioComponent buildings={preLancamento} />
      {lancamentos.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "32px",
          }}
        >
          <h2
            style={{
              width: "35%",
              textAlign: "center",
              paddingBottom: "12px",
              borderBottomWidth: "1.5px",
              borderBottomColor: "black",
            }}
          >
            Lançamento
          </h2>
        </div>
      )}
      <PredioComponent buildings={lancamentos} />
      {emConstrucao.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "32px",
          }}
        >
          <h2
            style={{
              width: "35%",
              textAlign: "center",
              paddingBottom: "12px",
              borderBottomWidth: "1.5px",
              borderBottomColor: "black",
            }}
          >
            Em construção
          </h2>
        </div>
      )}
      <PredioComponent buildings={emConstrucao} />
      {entregue.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "32px",
          }}
        >
          <h2
            style={{
              width: "35%",
              textAlign: "center",
              paddingBottom: "12px",
              borderBottomWidth: "1.5px",
              borderBottomColor: "black",
            }}
          >
            Entregue
          </h2>
        </div>
      )}
      <PredioComponent buildings={entregue} />
    </Layout>
  );
}
